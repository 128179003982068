export const dummyState = {
  privateCustomer: {
    showSalesApplicationRoute: true,
    isLoadingGlobally: false,
    errors: [],
    messages: [],
    config: {
      showFundingProjects: false,
      preventRedirect: false,
      links: {
        cancellationPolicy:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        privacyPolicy:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        creditAssessment:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        egbgbInformation:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        generalSupplyConditions: {
          electricity:
            "http://www.evo.test/fileadmin/user_upload/EEEEEEEEEEEETest-Dateien/dummy.pdf",
          gas:
            "http://www.evo.test/fileadmin/user_upload/GGGGGGGGGGGGGGTest-Dateien/dummy.pdf",
        },
        supplementaryTerms: {
          electricity:
            "http://www.evo.test/fileadmin/user_upload/EEEEEEEEEEEEEEETest-Dateien/dummy.pdf",
          gas:
            "http://www.evo.test/fileadmin/user_upload/GGGGGGGGGGGGGTest-Dateien/dummy.pdf",
        },
        successPages: {
          privateCustomer: {
            alreadyCustomer: "http://www.evo.test/marktkommunikation",
            electricity: "http://www.evo.test/danke/upselling/strom",
            gas: "http://www.evo.test/danke/upselling/gas",
            heating: "http://www.evo.test/danke/upselling/heizstrom",
          },
          businessCustomer: {
            alreadyCustomer: "http://www.evo.test/marktkommunikation",
            electricity: "http://www.evo.test/danke/upselling/strom",
            gas: "http://www.evo.test/danke/upselling/gas",
            heating: "http://www.evo.test/danke/upselling/heizstrom",
          },
        },
        errorPage: "http://www.evo.test/es-tut-uns-leid/",
      },
      NEUEINZUG_BIS: "2022-10-20",
      NEUEINZUG_VON: "2020-09-15",
      VERSORGERWECHSEL_BIS: "2022-10-20",
      VERSORGERWECHSEL_VON: "2020-11-04",
      VERBRAUCH_HT_PROZENT: "75",
      VERBRAUCH_NT_PROZENT: "25",
    },
    steps: [
      {
        id: "SalesApplicationTariffStep",
        headline: "Ihr Tarif",
        state: "visited",
        locked: true,
      },
      {
        id: "SalesApplicationPersonalDetailsStep",
        headline: "Persönliche Angaben",
        businessCustomerHeadline: "Firmendaten",
        state: "active",
      },
      {
        id: "SalesApplicationPaymentStep",
        headline: "Zahlungsweise",
        state: "unvisited",
      },
      {
        id: "SalesApplicationDeliveryStep",
        headline: "Angaben zur Belieferung",
        state: "unvisited",
      },
      {
        id: "SalesApplicationCompleteStep",
        headline: "Fertigstellen",
        state: "unvisited",
        checked: true,
      },
    ],
    formData: {
      salesApplicationTariffStep: {
        product: {
          calculatorMode: "Electricity",
          customerType: "1",
          address: {
            CITY1: "Oberhausen",
            POST_CODE1: "46145",
            LINE: "Oberhausen",
            LABEL: "46145 - Oberhausen",
            OBERHAUSEN: "X",
          },
          freeKwh: "3000",
          bonus: "250",
          powerConsumption: "3400",
          id: "EJPTOBFAM1",
          sponsorshipId: [],
          name: "TOB-Strom Family",
          divisionType: "10",
          properties: ["SEPA", "BANKWIRE", "ONLINEREG"],
          priority: "40",
          defaultProviderDifference: "",
          prices: {
            workingPrice: [
              {
                PREIS_ART: "1",
                PREIS_ART_TEXT: "Arbeitspreis",
                BRUTTO: "27.144",
                NETTO: "23.4",
                EINHEIT: "Cent/kWh",
                PREIS_COMP: {
                  "_-CS67_-ISS_PRICE_COMP": {
                    BESCHREIBUNG: [],
                    BRUTTO: "27.144",
                    NETTO: "23.4",
                    EINHEIT: "Cent/kWh",
                  },
                },
                VALID_FROM: "2019-01-01",
                VALID_TO: "9999-12-31",
                DESCRIPTION: [],
                STAFFELN: {
                  ZNLI_ISS_PREISSTAFFEL: {
                    BRUTTO: "27.144",
                    NETTO: "23.4",
                    EINHEIT: "Cent/kWh",
                    VONZONE: "0000000000",
                    BISZONE: "9999999999",
                  },
                },
              },
            ],
            basePrice: [
              {
                PREIS_ART: "2",
                PREIS_ART_TEXT: "Grundpreis",
                BRUTTO: "116.3944",
                NETTO: "100.34",
                EINHEIT: "EUR/Jahr",
                PREIS_COMP: {
                  "_-CS67_-ISS_PRICE_COMP": {
                    BESCHREIBUNG: [],
                    BRUTTO: "116.3944",
                    NETTO: "100.34",
                    EINHEIT: "EUR/Jahr",
                  },
                },
                VALID_FROM: "2019-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: {
                  ZNLI_ISS_PREISSTAFFEL: {
                    BRUTTO: "116.3944",
                    NETTO: "100.34",
                    EINHEIT: "EUR/Jahr",
                    VONZONE: "0000000000",
                    BISZONE: "9999999999",
                  },
                },
              },
              {
                PREIS_ART: "2",
                PREIS_ART_TEXT: "Grundpreis",
                BRUTTO: "142.1696",
                NETTO: "122.56",
                EINHEIT: "EUR/Jahr",
                PREIS_COMP: {
                  "_-CS67_-ISS_PRICE_COMP": {
                    BESCHREIBUNG: [],
                    BRUTTO: "142.1696",
                    NETTO: "122.56",
                    EINHEIT: "EUR/Jahr",
                  },
                },
                VALID_FROM: "2021-01-01",
                VALID_TO: "9999-12-31",
                DESCRIPTION: [],
                STAFFELN: {
                  ZNLI_ISS_PREISSTAFFEL: {
                    BRUTTO: "142.1696",
                    NETTO: "122.56",
                    EINHEIT: "EUR/Jahr",
                    VONZONE: "0000000000",
                    BISZONE: "9999999999",
                  },
                },
              },
            ],
            bonusPrice: [],
            annualCosts: [
              {
                PREIS_ART: "5",
                PREIS_ART_TEXT: "Jahreskosten",
                BRUTTO: "1039.29",
                NETTO: "895.94",
                EINHEIT: "EUR",
                PREIS_COMP: [],
                VALID_FROM: "2019-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: [],
              },
            ],
            monthlyDiscount: [
              {
                PREIS_ART: "6",
                PREIS_ART_TEXT: "Monatlicher Abschlag",
                BRUTTO: "87.0",
                NETTO: "75.0",
                EINHEIT: "EUR",
                PREIS_COMP: [],
                VALID_FROM: "2019-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: [],
              },
            ],
            monthlyBasePrice: [
              {
                PREIS_ART: "8",
                PREIS_ART_TEXT: "Monatlicher Grundpreis",
                BRUTTO: "9.69953333",
                NETTO: "8.36166667",
                EINHEIT: "EUR",
                PREIS_COMP: {
                  "_-CS67_-ISS_PRICE_COMP": {
                    BESCHREIBUNG: [],
                    BRUTTO: "116.3944",
                    NETTO: "100.34",
                    EINHEIT: "EUR/Jahr",
                  },
                },
                VALID_FROM: "2019-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: [],
              },
            ],
          },
          hasBulkPrices: false,
          hasIncreasedBasePrice: true,
          hasIncreasedWorkingPrice: false,
          hasPropertiePricescale: false,
          isBasicSupplierTariff: false,
          descriptionInfo:
            '<li><sup class="text-orange-500">**</sup> Ich erhalte eine Prämienkarte (Gutscheinkarte) im Wert von 50 € (brutto), einzulösen innerhalb eines Jahres ab Erhalt bei einem der unter www.evo-energie.de/family genannten Prämienpartner der evo zum Tarif TOB-StromFamily. Die Prämienkarte kann nur einmalig eingelöst werden, bei Inanspruchnahme eines Werts von weniger als 50 € (brutto) verfällt der Restbetrag.</li>',
          advantages:
            "<li>100% Strom aus Wasserkraft, TÜV Süd zertifiziert</li> <li>volle Preisgarantie bis Ende 2021</li> <li>50 € Prämienkarte</li>",
          header: "",
          prodDesc:
            "<li>100% Strom aus Wasserkraft, TÜV Süd zertifiziert</li> <li>Familien-Newsletter mit Informationen und Tipps für Ihre ganze Familie</li> <li>50 € Gutscheinkarte zur Einlösung bei ausgewählten Oberhausener Prämienpartnern**</li> <li>persönliche und telefonische Kundenberatung</li> <li>Zahlung per SEPA-Lastschrift oder Überweisung möglich</li> <li>Erstvertragslaufzeit: 24 Monate</li> <li>Preisgarantie: bis zum 31.12.2021</li> <li>Vertragsverlängerung bei Nichtkündigung: jeweils um 12 Monate</li> <li>Kündigungsfrist: 2 Monate zum Vertragsende</li>",
          duration: "",
          highlight: "",
          perfDesc: "",
          badges: "PRAEMIE",
          special: "Prämienkarte-Gutschein im Wert von 50 € brutto",
          spHint:
            "Ich erhalte eine Prämienkarte (Gutscheinkarte) im Wert von 50 € (brutto), einzulösen innerhalb eines Jahres ab Erhalt bei einem der unter www.evo-energie.de/family genannten Prämienpartner der evo zum Tarif TOB-Strom Family. Die Prämienkarte kann nur einmalig eingelöst werden, bei Inanspruchnahme eines Werts von weniger als 50 € (brutto) verfällt der Restbetrag.",
          initialTermInfo: "2 Jahre ab Lieferbeginn",
          contractRenewalInfo: "1 Jahr bei Nichtkündigung",
          termOfNoticeInfo: "2 Monate zum Vertragsende",
          guaranteeType: false,
          guaranteeInfo: "bis zum 31.12.2021",
          tariffHeaderSrc:
            "http://www.evo.test/fileadmin/user_upload/Tarife/Tarifkoepfe/EJPTOBFAM1.svg",
          tariffHeaderNegativeSrc:
            "http://www.evo.test/fileadmin/user_upload/Tarife/Tarifkoepfe/EJPTOBFAM1_n.svg",
          tariffBadgeSrc:
            "http://www.evo.test/fileadmin/user_upload/Tarife/Badges/CO_BADGES_PRAEMIE.svg",
          consumption: "3400",
          priceUnit: "BRUTTO",
        },
      },
      // salesApplicationFundingProjectsStep: {
      //   affiliateId: "",
      //   fundingProject: {},
      //   loading: false,
      // },
      salesApplicationPersonalDetailsStep: {
        salutation: { TITLE: "0002", TITLE_MEDI: "Herrn" },
        firstName: "Max",
        lastName: "Mustermann",
        birthDate: "1992-01-01",
        phone: "004915221234567",
        emailAddress: "max@mustermann.de",
        contactPersonSalutation: null,
        companyName: "Company",
        companySuffix: "123",
        branchType: {
          SPRAS: "D",
          ISTYPE: "ZEVO",
          IND_SECTOR: "M0000",
          TEXT: "Erziehung und Bildung",
        },
      },
      salesApplicationPaymentStep: {
        paymentMethod: "transfer",
        paymentMethodData: {},
        accountHolder: "",
        bankName: "",
        iban: "",
        swift: "",
        doc: true,
        // paymentMethod: "sepa",
        // accountHolder: "Max Mustermann",
        // bankName: "ING-DiBa",
        // iban: "DE8050010517348494s7728",
        // swift: "INGDDEFFXXX",
        // doc: true,
      },
      salesApplicationDeliveryStep: {
        deliveryMethod: "01",
        deliveryMethodData: {
          name: "deliveryMethod",
          value: "01",
          title: "Neueinzug",
          description:
            "Wir möchten an unserem neuen Standort durch die evo beliefert werden.",
          checked: true,
        },
        deliveryStart: "2020-09-16",
        previousTenant: "Volker Vormieter",
        previousSupplier: "",
        previousSupplierData: null,
        previousCustomerNumber: "",
        meterNumber: "7503863827482T",
        maLoID: "16237652162",
        meterReading: "7872",
        dateMeterReading: "",
        cancellationType: "",
        cancellationTypeData: "",
        terminationDate: "",
        deliveryAddress: {
          addressResponse: {
            CITY1: "Oberhausen",
            POST_CODE1: "46147",
            LINE: "Oberhausen",
            LABEL: "46147 - Oberhausen",
            OBERHAUSEN: "X",
          },
          street: { LINE: "Burgstr.", CODE: "002324300074" },
          houseNumber: "23",
          additionalAddress: "A",
        },
        differentBillingAddress: false,
        billingAddress: {
          addressResponse: null,
          salutation: null,
          firstName: "",
          lastName: "",
          addressType: "",
          street: null,
          houseNumber: "",
          mailboxNumber: "",
        },
      },
      salesApplicationCompleteStep: {
        declarationsOfConsentAccepted: null,
        informationAndOffersAccepted: null,
      },
    },
  },
  businessCustomer: {
    showSalesApplicationRoute: true,
    isLoadingGlobally: false,
    errors: [],
    messages: [],
    config: {
      showFundingProjects: false,
      preventRedirect: false,
      links: {
        cancellationPolicy:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        privacyPolicy:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        creditAssessment:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        egbgbInformation:
          "http://www.evo.test/fileadmin/user_upload/Test-Dateien/dummy.pdf",
        generalSupplyConditions: {
          electricity:
            "http://www.evo.test/fileadmin/user_upload/EEEEEEEEEEEETest-Dateien/dummy.pdf",
          gas:
            "http://www.evo.test/fileadmin/user_upload/GGGGGGGGGGGGGGTest-Dateien/dummy.pdf",
        },
        supplementaryTerms: {
          electricity:
            "http://www.evo.test/fileadmin/user_upload/EEEEEEEEEEEEEEETest-Dateien/dummy.pdf",
          gas:
            "http://www.evo.test/fileadmin/user_upload/GGGGGGGGGGGGGTest-Dateien/dummy.pdf",
        },
        successPages: {
          privateCustomer: {
            electricity: "http://www.evo.test/danke/upselling/strom",
            gas: "http://www.evo.test/danke/upselling/gas",
            heating: "http://www.evo.test/danke/upselling/heizstrom",
          },
          businessCustomer: {
            electricity: "http://www.evo.test/danke/upselling/strom",
            gas: "http://www.evo.test/danke/upselling/gas",
            heating: "http://www.evo.test/danke/upselling/heizstrom",
          },
        },
        errorPage: "http://www.evo.test/es-tut-uns-leid/",
      },
      NEUEINZUG_BIS: "2022-10-21",
      NEUEINZUG_VON: "2020-09-16",
      VERSORGERWECHSEL_BIS: "2022-10-21",
      VERSORGERWECHSEL_VON: "2020-11-05",
      VERBRAUCH_HT_PROZENT: "75",
      VERBRAUCH_NT_PROZENT: "25",
    },
    steps: [
      {
        id: "SalesApplicationTariffStep",
        headline: "Ihr Tarif",
        state: "visited",
        locked: true,
        // customToggleEdit: {
        //   _custom: {
        //     type: "function",
        //     display: "<span>ƒ</span> tariffStepEditCallback()",
        //   },
        // },
      },
      {
        id: "SalesApplicationPersonalDetailsStep",
        headline: "Persönliche Angaben",
        businessCustomerHeadline: "Firmendaten",
        state: "active",
      },
      {
        id: "SalesApplicationPaymentStep",
        headline: "Zahlungsweise",
        state: "unvisited",
      },
      {
        id: "SalesApplicationDeliveryStep",
        headline: "Angaben zur Belieferung",
        state: "unvisited",
      },
      {
        id: "SalesApplicationCompleteStep",
        headline: "Fertigstellen",
        state: "unvisited",
        checked: true,
      },
    ],
    formData: {
      salesApplicationTariffStep: {
        product: {
          calculatorMode: "Electricity",
          customerType: "2",
          address: {
            CITY1: "Oberhausen",
            POST_CODE1: "46147",
            LINE: "Oberhausen",
            LABEL: "46147 - Oberhausen",
            OBERHAUSEN: "X",
          },
          powerConsumption: "3400",
          id: "EJGA-PRONA",
          sponsorshipId: [],
          name: "TOB-Strom Profi Natur",
          divisionType: "10",
          properties: ["SEPA", "BANKWIRE", "ONLINEREG", "PRICESCALE"],
          priority: "40",
          defaultProviderDifference: "",
          prices: {
            workingPrice: [
              {
                PREIS_ART: "1",
                PREIS_ART_TEXT: "Arbeitspreis",
                BRUTTO: "27.2832",
                NETTO: "23.52",
                EINHEIT: "Cent/kWh",
                PREIS_COMP: [],
                VALID_FROM: "2017-01-01",
                VALID_TO: "9999-12-31",
                DESCRIPTION: [],
                STAFFELN: {
                  ZNLI_ISS_PREISSTAFFEL: [
                    {
                      BRUTTO: "27.2832",
                      NETTO: "23.52",
                      EINHEIT: "Cent/kWh",
                      VONZONE: "0000000000",
                      BISZONE: "0000010000",
                    },
                    {
                      BRUTTO: "26.9932",
                      NETTO: "23.27",
                      EINHEIT: "Cent/kWh",
                      VONZONE: "0000010000",
                      BISZONE: "0000100000",
                    },
                    {
                      BRUTTO: "27.1092",
                      NETTO: "23.37",
                      EINHEIT: "Cent/kWh",
                      VONZONE: "0000100000",
                      BISZONE: "9999999999",
                    },
                  ],
                },
              },
            ],
            basePrice: [
              {
                PREIS_ART: "2",
                PREIS_ART_TEXT: "Grundpreis",
                BRUTTO: "175.2876",
                NETTO: "151.11",
                EINHEIT: "EUR/Jahr",
                PREIS_COMP: [],
                VALID_FROM: "2020-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: {
                  ZNLI_ISS_PREISSTAFFEL: [
                    {
                      BRUTTO: "175.2876",
                      NETTO: "151.11",
                      EINHEIT: "EUR/Jahr",
                      VONZONE: "0000000000",
                      BISZONE: "0000010000",
                    },
                    {
                      BRUTTO: "204.2876",
                      NETTO: "176.11",
                      EINHEIT: "EUR/Jahr",
                      VONZONE: "0000010000",
                      BISZONE: "0000100000",
                    },
                    {
                      BRUTTO: "204.2876",
                      NETTO: "176.11",
                      EINHEIT: "EUR/Jahr",
                      VONZONE: "0000100000",
                      BISZONE: "9999999999",
                    },
                  ],
                },
              },
              {
                PREIS_ART: "2",
                PREIS_ART_TEXT: "Grundpreis",
                BRUTTO: "160.9732",
                NETTO: "138.77",
                EINHEIT: "EUR/Jahr",
                PREIS_COMP: [],
                VALID_FROM: "2021-01-01",
                VALID_TO: "9999-12-31",
                DESCRIPTION: [],
                STAFFELN: {
                  ZNLI_ISS_PREISSTAFFEL: [
                    {
                      BRUTTO: "160.9732",
                      NETTO: "138.77",
                      EINHEIT: "EUR/Jahr",
                      VONZONE: "0000000000",
                      BISZONE: "0000010000",
                    },
                    {
                      BRUTTO: "189.9732",
                      NETTO: "163.77",
                      EINHEIT: "EUR/Jahr",
                      VONZONE: "0000010000",
                      BISZONE: "0000100000",
                    },
                    {
                      BRUTTO: "189.9732",
                      NETTO: "163.77",
                      EINHEIT: "EUR/Jahr",
                      VONZONE: "0000100000",
                      BISZONE: "9999999999",
                    },
                  ],
                },
              },
            ],
            bonusPrice: [],
            annualCosts: [
              {
                PREIS_ART: "5",
                PREIS_ART_TEXT: "Jahreskosten",
                BRUTTO: "1102.92",
                NETTO: "950.79",
                EINHEIT: "EUR",
                PREIS_COMP: [],
                VALID_FROM: "2020-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: [],
              },
            ],
            monthlyDiscount: [
              {
                PREIS_ART: "6",
                PREIS_ART_TEXT: "Monatlicher Abschlag",
                BRUTTO: "92.0",
                NETTO: "80.0",
                EINHEIT: "EUR",
                PREIS_COMP: [],
                VALID_FROM: "2020-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: [],
              },
            ],
            monthlyBasePrice: [
              {
                PREIS_ART: "8",
                PREIS_ART_TEXT: "Monatlicher Grundpreis",
                BRUTTO: "14.6073",
                NETTO: "12.5925",
                EINHEIT: "EUR",
                PREIS_COMP: [],
                VALID_FROM: "2020-01-01",
                VALID_TO: "2020-12-31",
                DESCRIPTION: [],
                STAFFELN: [],
              },
            ],
          },
          hasBulkPrices: true,
          hasIncreasedBasePrice: true,
          hasIncreasedWorkingPrice: false,
          hasPropertiePricescale: true,
          isBasicSupplierTariff: false,
          descriptionInfo: "",
          advantages:
            "<li>100% Strom aus Wasserkraft, TÜV Süd zertifiziert</li> <li>Energie sparen wird belohnt</li> <li>Persönlicher Service – Kostenlose Hotline & Service vor Ort</li>",
          header: "",
          prodDesc:
            "<li>100% Strom aus Wasserkraft, TÜV Süd zertifiziert</li> <li>Energie sparen wird belohnt</li> <li>persönliche und telefonische Kundenberatung</li> <li>Zahlung per SEPA-Lastschrift oder Überweisung möglich</li> <li>Erstvertragslaufzeit: 12 Monate</li> <li>Eingeschränkte Preisgarantie: bis zum 31.12.2020</li> <li>Vertragsverlängerung bei Nichtkündigung: jeweils um 12 Monate</li> <li>Kündigungsfrist: 2 Monate zum Vertragsende</li>",
          duration: "",
          highlight: "",
          perfDesc: "",
          badges: "OEKO",
          special: "",
          spHint: "",
          initialTermInfo: "1 Jahr ab Lieferbeginn",
          contractRenewalInfo: "1 Jahr bei Nichtkündigung",
          termOfNoticeInfo: "2 Monate zum Vertragsende",
          guaranteeType: true,
          guaranteeInfo: "bis zum 31.12.2020",
          tariffHeaderSrc:
            "http://www.evo.test/fileadmin/user_upload/Tarife/Tarifkoepfe/EJGA-PRONA.svg",
          tariffHeaderNegativeSrc:
            "http://www.evo.test/fileadmin/user_upload/Tarife/Tarifkoepfe/EJGA-PRONA_n.svg",
          tariffBadgeSrc:
            "http://www.evo.test/fileadmin/user_upload/Tarife/Badges/CO_BADGES_OEKO.svg",
          consumption: "3400",
          priceUnit: "NETTO",
        },
      },
      // salesApplicationFundingProjectsStep: {
      //   affiliateId: "",
      //   fundingProject: {},
      //   loading: false,
      // },
      salesApplicationPersonalDetailsStep: {
        salutation: null,
        firstName: "Helmut",
        lastName: "Hansmann",
        birthDate: "1992-01-01",
        phone: "0236487732876",
        emailAddress: "sad@mail.de",
        contactPersonSalutation: { TITLE: "0002", TITLE_MEDI: "Herrn" },
        companyName: "Neuer GmbH & Co. KG",
        companySuffix: "Zusatzsatz",
        branchType: {
          SPRAS: "D",
          ISTYPE: "ZEVO",
          IND_SECTOR: "M0000",
          TEXT: "Erziehung und Bildung",
        },
      },
      salesApplicationPaymentStep: {
        paymentMethod: "",
        paymentMethodData: {
          name: "paymentMethod",
          value: "transfer",
          title: "Transfer-Lastschrift",
          checked: true,
        },
        accountHolder: "",
        bankName: "",
        iban: "",
        swift: "",
        doc: false,
        value: {
          paymentMethod: "transfer",
          paymentMethodData: {},
          accountHolder: "Donald Duck",
          bankName: "ING-DiBa",
          iban: "DE80500105173484947728",
          swift: "INGDDEFFXXX",
          doc: true,
        },
      },
      salesApplicationDeliveryStep: {
        deliveryMethod: "01",
        deliveryMethodData: {
          name: "deliveryMethod",
          value: "01",
          title: "Neueinzug",
          description:
            "Wir möchten an unserem neuen Standort durch die evo beliefert werden.",
          checked: true,
        },
        deliveryStart: "2020-09-16",
        previousTenant: "Volker Vormieter",
        previousSupplier: "",
        previousSupplierData: null,
        previousCustomerNumber: "",
        meterNumber: "7503863827482T",
        maLoID: "16237652162",
        meterReading: "7872",
        dateMeterReading: "",
        cancellationType: "",
        cancellationTypeData: "",
        terminationDate: "",
        deliveryAddress: {
          addressResponse: {
            CITY1: "Oberhausen",
            POST_CODE1: "46147",
            LINE: "Oberhausen",
            LABEL: "46147 - Oberhausen",
            OBERHAUSEN: "X",
          },
          street: { LINE: "Burgstr.", CODE: "002324300074" },
          houseNumber: "23",
          additionalAddress: "A",
        },
        differentBillingAddress: false,
        billingAddress: {
          addressResponse: null,
          salutation: null,
          firstName: "",
          lastName: "",
          addressType: "",
          street: null,
          houseNumber: "",
          mailboxNumber: "",
        },
      },
      salesApplicationCompleteStep: {
        declarationsOfConsentAccepted: null,
        informationAndOffersAccepted: null,
      },
    },
  },
};
