export const products = [
  {
    PRODUCT_ID: "EJPA---PUR",
    SPARTE: "10",
    PROD_TYPE: [],
    PROD_PROP: {
      "_-CS67_-PROD_PROP": ["SEPA", "BANKWIRE", "ONLINEREG"],
    },
    PRODUCT_NAME: "TOB-Strom Pur",
    PRICES: {
      "_-CS67_-ISS_PRICE_INFO": [
        {
          PREIS_ART: "1",
          PREIS_ART_TEXT: "Arbeitspreis",
          BRUTTO: "27.9174",
          NETTO: "23.46",
          EINHEIT: "Cent/kWh",
          PREIS_COMP: [],
          VALID_FROM: "2017-04-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "2",
          PREIS_ART_TEXT: "Grundpreis",
          BRUTTO: "147.4529",
          NETTO: "123.91",
          EINHEIT: "EUR/Jahr",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "5",
          PREIS_ART_TEXT: "Jahreskosten",
          BRUTTO: "901.22",
          NETTO: "757.33",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "6",
          PREIS_ART_TEXT: "Monatlicher Abschlag",
          BRUTTO: "76.0",
          NETTO: "64.0",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "8",
          PREIS_ART_TEXT: "Monatlicher Grundpreis",
          BRUTTO: "12.28774167",
          NETTO: "10.32583333",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
      ],
    },
    PROD_DESC: {
      "_-CS67_-ISS_PROD_DESC": [
        {
          PROD_DESC_TYPE: "DURATION",
          PROD_DESC_CONTENT: "Erstlaufzeit 12 Monate",
        },
        {
          PROD_DESC_TYPE: "PROD_DESC",
          PROD_DESC_CONTENT:
            "<li>Erstlaufzeit 12 Monate</li> <li>K&uuml;ndigungsfrist 2 Monate zum Monatsende</li> <li>autom. Verl&auml;ngerung um 1 Jahr bei gleicher K&uuml;ndigungsfrist </li> <li>pers&ouml;nliche und telefonische Kundenberatung</li> <li>Zahlung per SEPA-Lastschrift oder &Uuml;berweisung m&ouml;glich</li>",
        },
        {
          PROD_DESC_TYPE: "HIGHLIGHT",
          PROD_DESC_CONTENT:
            "<li>Preissicherheit bis Jahresende</li> <li>K&uuml;ndigungsfrist 2 Monate zum Monatsende</li> <li>autom. Verl&auml;ngerung um 1 Jahr bei gleicher K&uuml;ndigungsfrist </li> <li>pers&ouml;nliche und telefonische Kundenberatung</li>",
        },
        {
          PROD_DESC_TYPE: "PERF_DESC",
          PROD_DESC_CONTENT:
            "<li>die Konzessionsabgabe, die an die jeweilige Stadt abzuführen ist </li> <li>die gesetzliche Stromsteuer </li> <li>Belastungen aus dem Gesetz für den Vorrang Erneuerbarer Energien </li> <li>die Kraft-Wärme-Kopplungsgesetz-Umlage</li> <li>die § 19 StromNEV Umlage</li> <li>die §17f EnWG Offshore-Haftungsumlage</li> <li>die Umlage §18 für Abschaltbare Lasten</li> <li>sämtliche Netzentgelte</li>",
        },
        {
          PROD_DESC_TYPE: "ADVANTAGES",
          PROD_DESC_CONTENT:
            "<li>Sehr gutes Preis-/ Leistungsverhältnis</li> <li>Persönlicher Service – Kostenlose Hotline & Service vor Ort</li>",
        },
        {
          PROD_DESC_TYPE: "BADGES",
          PROD_DESC_CONTENT: "PL_SIEGER",
        },
      ],
    },
    PRIORITY: "50",
    ACTIVE: [],
    ERSTLAUF: "001",
    ERSTPERIO: "2",
    ERSTLAUF_ENDE: "2021-06-24",
    KUENDDAT: "2021-04-24",
    KFRIST: "002",
    KUENPER: "1",
    VERLAENG: "001",
    VERPER: "2",
    ABSZYK: "01",
    NKB: "0.0",
    PREISGARANTIE: "2020-12-31",
    GARANTIEMONATE: "000",
    REGION_ID: "000001",
    SPONSORSHIP_ID: [],
    ANLART: [],
    PROD_GROUP: {
      ZNLI_PRODUCT_GROUP: "EVO",
    },
  },
  {
    PRODUCT_ID: "EJPTOBFAM1",
    SPARTE: "10",
    PROD_TYPE: [],
    PROD_PROP: {
      "_-CS67_-PROD_PROP": ["SEPA", "BANKWIRE", "ONLINEREG"],
    },
    PRODUCT_NAME: "TOB-Strom Family",
    PRICES: {
      "_-CS67_-ISS_PRICE_INFO": [
        {
          PREIS_ART: "1",
          PREIS_ART_TEXT: "Arbeitspreis",
          BRUTTO: "27.846",
          NETTO: "23.4",
          EINHEIT: "Cent/kWh",
          PREIS_COMP: {
            "_-CS67_-ISS_PRICE_COMP": {
              BESCHREIBUNG: [],
              BRUTTO: "27.846",
              NETTO: "23.4",
              EINHEIT: "Cent/kWh",
            },
          },
          VALID_FROM: "2019-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "2",
          PREIS_ART_TEXT: "Grundpreis",
          BRUTTO: "119.4046",
          NETTO: "100.34",
          EINHEIT: "EUR/Jahr",
          PREIS_COMP: {
            "_-CS67_-ISS_PRICE_COMP": {
              BESCHREIBUNG: [],
              BRUTTO: "119.4046",
              NETTO: "100.34",
              EINHEIT: "EUR/Jahr",
            },
          },
          VALID_FROM: "2019-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "5",
          PREIS_ART_TEXT: "Jahreskosten",
          BRUTTO: "871.25",
          NETTO: "732.14",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2019-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "6",
          PREIS_ART_TEXT: "Monatlicher Abschlag",
          BRUTTO: "73.0",
          NETTO: "62.0",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2019-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "8",
          PREIS_ART_TEXT: "Monatlicher Grundpreis",
          BRUTTO: "9.95038333",
          NETTO: "8.36166667",
          EINHEIT: "EUR",
          PREIS_COMP: {
            "_-CS67_-ISS_PRICE_COMP": {
              BESCHREIBUNG: [],
              BRUTTO: "119.4046",
              NETTO: "100.34",
              EINHEIT: "EUR/Jahr",
            },
          },
          VALID_FROM: "2019-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
      ],
    },
    PROD_DESC: {
      "_-CS67_-ISS_PROD_DESC": [
        {
          PROD_DESC_TYPE: "PROD_DESC",
          PROD_DESC_CONTENT:
            "<li><b>Energiemix:</b> 100 % Wasserkraft </li> <li><b>Erstlaufzeit:</b> 24 Monate</li> <li><b>Preisgarantie**:</b> Preise bleiben bis 31.12.2021 stabil</li> <li><b>50 € Gutschein:</b> Ihre Prämienkarte zur Einlösung bei ausgewählten Oberhausener Prämienpartnern</li> <li><b>Familien-Newsletter:</b> Informationen und Tipps für Ihre ganze Familie</li> <li><b>evo-Kundenkarte:</b> viele Vergünstigungen für Ihre Familie</li> <li><b>Verlängerung:</b> 12 Monate bei Nichtkündigung</li> <li><b>Kündigungsfrist:</b> zwei Monate zum Ende der jeweiligen Vertragslaufzeit</li> <li><b>Kundenberatung:</b>persönlich und telefonisch</li> <li><b>Zahlung:</b>per SEPA-Lastschrift oder Überweisung möglich </li> <br> <p>** Ausgenommen Veränderungen der Umsatzsteuer und Einführung neuer staatlich veranlasster Kostenbestandteile</p>",
        },
        {
          PROD_DESC_TYPE: "ADVANTAGES",
          PROD_DESC_CONTENT:
            "<li>100 % Strom aus Wasserkraft, TÜV Süd zertifiziert</li> <li>Volle Preisgarantie bis Ende 2021</li> <li>50 € Prämienkarte</li>",
        },
        {
          PROD_DESC_TYPE: "BADGES",
          PROD_DESC_CONTENT: "PRAEMIE",
        },
      ],
    },
    PRIORITY: "40",
    ACTIVE: [],
    ERSTLAUF: "002",
    ERSTPERIO: "2",
    ERSTLAUF_ENDE: "2022-06-24",
    KUENDDAT: "2022-04-24",
    KFRIST: "002",
    KUENPER: "1",
    VERLAENG: "001",
    VERPER: "2",
    ABSZYK: "01",
    NKB: "0.0",
    PREISGARANTIE: "2021-12-31",
    GARANTIEMONATE: "000",
    REGION_ID: "000001",
    SPONSORSHIP_ID: [],
    ANLART: [],
    PROD_GROUP: {
      ZNLI_PRODUCT_GROUP: "EVO",
    },
  },
  {
    PRODUCT_ID: "EJPA-PLUSR",
    SPARTE: "10",
    PROD_TYPE: [],
    PROD_PROP: {
      "_-CS67_-PROD_PROP": ["SEPA", "BANKWIRE", "ONLINEREG"],
    },
    PRODUCT_NAME: "TOB-Strom Plus 3",
    PRICES: {
      "_-CS67_-ISS_PRICE_INFO": [
        {
          PREIS_ART: "1",
          PREIS_ART_TEXT: "Arbeitspreis",
          BRUTTO: "27.9174",
          NETTO: "23.46",
          EINHEIT: "Cent/kWh",
          PREIS_COMP: [],
          VALID_FROM: "2017-04-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "2",
          PREIS_ART_TEXT: "Grundpreis",
          BRUTTO: "147.4529",
          NETTO: "123.91",
          EINHEIT: "EUR/Jahr",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "5",
          PREIS_ART_TEXT: "Jahreskosten",
          BRUTTO: "901.22",
          NETTO: "757.33",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "6",
          PREIS_ART_TEXT: "Monatlicher Abschlag",
          BRUTTO: "76.0",
          NETTO: "64.0",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
        {
          PREIS_ART: "8",
          PREIS_ART_TEXT: "Monatlicher Grundpreis",
          BRUTTO: "12.28774167",
          NETTO: "10.32583333",
          EINHEIT: "EUR",
          PREIS_COMP: [],
          VALID_FROM: "2020-01-01",
          VALID_TO: "9999-12-31",
          DESCRIPTION: [],
          STAFFELN: [],
        },
      ],
    },
    PROD_DESC: {
      "_-CS67_-ISS_PROD_DESC": [
        {
          PROD_DESC_TYPE: "DURATION",
          PROD_DESC_CONTENT: "Erstlaufzeit 12 Monate",
        },
        {
          PROD_DESC_TYPE: "PROD_DESC",
          PROD_DESC_CONTENT:
            "<li>Erstlaufzeit 12 Monate</li> <li>K&uuml;ndigungsfrist 2 Monate zum Monatsende</li> <li>autom. Verl&auml;ngerung um 1 Jahr bei gleicher K&uuml;ndigungsfrist</li> <li>3 % bzw. 1,5 % Rabatt auf den Nettorechnungsbetrag bei j&auml;hrlicher bzw. halbj&auml;hrlicher Vorauszahlung</li> <li>pers&ouml;nliche und telefonische Kundenberatung</li> <li>Zahlung per SEPA-Lastschrift oder &Uuml;berweisung m&ouml;glich</li>",
        },
        {
          PROD_DESC_TYPE: "HIGHLIGHT",
          PROD_DESC_CONTENT:
            "<li>K&uuml;ndigungsfrist 2 Monate zum Monatsende</li> <li>autom. Verl&auml;ngerung um 1 Jahr bei gleicher K &uuml;ndigungsfrist</li> 3% bzw. 1,5% Rabatt auf den Nettorechnungsbetrag bei j&uml;hrlicher bzw. halbj&auml;hrlicher Vorauszahlung</li> <li>pers&ouml;nliche und telefonische Kundenberatung</li>",
        },
        {
          PROD_DESC_TYPE: "PERF_DESC",
          PROD_DESC_CONTENT:
            "<li>die Konzessionsabgabe, die an die jeweilige Stadt abzuführen ist </li> <li>die gesetzliche Stromsteuer </li> <li>Belastungen aus dem Gesetz für den Vorrang Erneuerbarer Energien </li> <li>die Kraft-Wärme-Kopplungsgesetz-Umlage</li> <li>die § 19 StromNEV Umlage</li> <li>die §17f EnWG Offshore-Haftungsumlage</li> <li>die Umlage §18 für Abschaltbare Lasten</li> <li>sämtliche Netzentgelte</li>",
        },
        {
          PROD_DESC_TYPE: "ADVANTAGES",
          PROD_DESC_CONTENT:
            "<li>Sehr gutes Preis-/Leistungsverhältnis</li> <li>Vorauszahlen und sparen</li> <li>Persönlicher Service – Kostenlose Hotline & Service vor Ort</li>",
        },
        {
          PROD_DESC_TYPE: "BADGES",
          PROD_DESC_CONTENT: "EMPFEHLUNG",
        },
      ],
    },
    PRIORITY: "30",
    ACTIVE: [],
    ERSTLAUF: "001",
    ERSTPERIO: "2",
    ERSTLAUF_ENDE: "2021-06-24",
    KUENDDAT: "2021-04-24",
    KFRIST: "002",
    KUENPER: "1",
    VERLAENG: "001",
    VERPER: "2",
    ABSZYK: "01",
    NKB: "0.0",
    PREISGARANTIE: "2020-12-31",
    GARANTIEMONATE: "000",
    REGION_ID: "000001",
    SPONSORSHIP_ID: [],
    ANLART: [],
    PROD_GROUP: {
      ZNLI_PRODUCT_GROUP: "EVO",
    },
  },
];
